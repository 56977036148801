<template>
  <div >
 
    <div>
      <b-row class="admin-text" >
        <h5 class="mb-3">Manage Movies !</h5>
      </b-row>         
    </div>
  
    <div>
      <b-tabs content-class="mt-3">
        <!-- First Tab to Display Records -->
        <b-tab title="All Records" active>
          <b-row>
            <b-col sm="12" class="mb-3"><b-form-input v-model="SearchBy" placeholder="Search by name"></b-form-input></b-col>
            <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
          </b-row>
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="SearchList"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
                <b-button variant="success" @click="DelMovie(data.item.seq_no)">E</b-button>
                <b-button variant="danger" @click="DelMovie(data.item.seq_no)">D</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
        <!-- Second Tab to Create new Records -->        
        <b-tab title="Create New Record">
          <b-row class="text-center">
            <b-col cols="8 my-4">
            <!-- <b-alert show variant="success"  v-if="MsgSuccess">Record created successfully!</b-alert>
            <b-alert show variant="danger"  v-if="MsgDanger">Error! Some Issue while create a new record!</b-alert>                  -->
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    placeholder="Enter Movie name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="year">
                  <b-form-input
                    id="year"
                    v-model="form.year"
                    placeholder="Enter Movie Year"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="language">
                  <b-form-input
                    id="language"
                    v-model="form.language"
                    placeholder="Enter Movie language"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="industry">
                  <b-form-input
                    id="industry"
                    v-model="form.industry"
                    placeholder="Enter Movie industry"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="country">
                  <b-form-input
                    id="country"
                    v-model="form.country"
                    placeholder="Enter Movie country"
                    required
                  ></b-form-input>                
                </b-form-group>                                               

                <b-form-group id="input-group-2" label-for="MovieType">
                  <b-form-select 
                    v-model="selectedType" 
                    :options="MovieType">
                  </b-form-select>
                </b-form-group>

                <b-form-textarea
                  id="textarea"
                  size="lg"
                  v-model="form.movie_desc"
                  placeholder="Enter Movie Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>                 

                <b-form-group id="input-group-2" label-for="movie_image">
                  <b-form-input
                    id="movie_image"
                    v-model="form.movie_image"
                    placeholder="Enter Movie movie_image"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="trailer_url">
                  <b-form-input
                    id="trailer_url"
                    v-model="form.trailer_url"
                    placeholder="Enter Movie trailer_url"
                    required
                  ></b-form-input>                
                </b-form-group>  

                <b-form-group id="input-group-2" label-for="watch_url">
                  <b-form-input
                    id="watch_url"
                    v-model="form.watch_url"
                    placeholder="Enter Movie watch_url"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="MovieStatus">
                  <b-form-select 
                    v-model="selectedStatus" 
                    :options="MovieStatus">
                  </b-form-select>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="relese_date">
                  <b-form-input
                    id="relese_date"
                    v-model="form.relese_date"
                    placeholder="Enter Movie relese_date"
                    required
                  ></b-form-input>                
                </b-form-group>                                                                              

                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Reset</b-button>
              </b-form>        
            </b-col>
          </b-row>          

        </b-tab>
        <!-- Third Tab to see Inactive Records -->        
        <b-tab title="Inactive Records">
            <div>
              <b-table striped hover outlined responsive="sm"
                :items="ListInactive"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"    
              >
              <template v-slot:cell(actions)="data">
                <b-button variant="success" @click="DelMovie(data.item.seq_no)">E</b-button>
                <b-button variant="danger" @click="DelMovie(data.item.seq_no)">D</b-button>
              </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>  
            </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
    name: "App",
    data() {
      return {
      fields: [{key: 'seq_no',sortable: true}, "movie_name", "language","movie_type","actions"],      
      perPage: 20,
      currentPage: 1,        
        form: {
            name: '',
            year: '',
            language: '',
            industry: '',
            country: '',
            selectedType: '',
            movie_desc: '',
            movie_image: '',
            trailer_url: '',
            watch_url: '',
            selectedStatus: '',
            relese_date: '',
        },
        RespArray: [],
        RespAllMovies: [],
        RespArrayIns: [],
        show: true,
        SearchBy: '',
        selectedType: 'default',
        selectedStatus: 'default',
        MovieType: [
          { value: 'default', text: 'Select Movie Type' },
          { value: 'Thriller', text: 'Thriller' },
          { value: 'Action', text: 'Action'},
          { value: 'Drama', text: 'Drama'},
        ],
        MovieStatus: [
          { value: 'default', text: 'Select Movie Status' },
          { value: 'Released', text: 'Released' },
          { value: 'Upcoming', text: 'Upcoming'},
        ],             
      }
    },
    mounted()
    {
      this.ReadAllMovies()
    },    
    methods: {
      onSubmit(event) {
      event.preventDefault()
      this.InsMovies()

      },
      ReadAllMovies(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllMovies')
        .then((resp)=>{
        this.RespAllMovies=resp.data.AllMovies;
      })
      },      
      InsMovies(){  
        const REQformData = new FormData();
        REQformData.append('movie_name', this.form.name);
        REQformData.append('year', this.form.year);
        REQformData.append('language', this.form.language);
        REQformData.append('industry', this.form.industry);
        REQformData.append('country', this.form.country);
        REQformData.append('movie_type', this.selectedType);
        REQformData.append('movie_desc', this.movie_desc);
        REQformData.append('movie_image', this.movie_image);
        REQformData.append('trailer_url', this.form.trailer_url);
        REQformData.append('watch_url', this.form.watch_url);
        REQformData.append('movie_status', this.selectedStatus);
        REQformData.append('relese_date', this.relese_date);                          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsMovies',REQformData)
        .then((resp)=>{
          this.RespArrayIns=resp.data.message;
          alert('Inserted Successfully!!')
          this.ReadAllMovies()
          this.ClearForms()
      })
      },
      DelMovie(seq_no){
       const InsformData = new FormData();
        InsformData.append('table_name', 'movies_main');
        InsformData.append('seq_no', seq_no);
        axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelTableRec', InsformData)
        .then((resp)=>{
        this.RespArray=resp.data.message;
        alert('Deleted Successfully!!')
        this.ReadAllMovies();
       }).catch(error => console.log(error));
       },
      ClearForms(){  
        this.form.name = '';
        this.form.year = '';
        this.form.language = '';
        this.form.industry = '';
        this.form.country = '';
        this.selectedType = 'default';
        this.movie_desc = '';
        this.movie_image = '';
        this.form.trailer_url = '';
        this.form.watch_url = '';
        this.selectedStatus = 'default';
        this.relese_date = '';       
      },                 
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      }
    },
    computed: {
    SearchList: function(){
        return this.RespAllMovies.filter((RespAllMovies) => {
        return RespAllMovies.movie_name.toLowerCase().match(this.SearchBy.toLowerCase()) && RespAllMovies.cde_status == 'P'
      });
    },
    ListInactive: function(){
        return this.RespAllMovies.filter((RespAllMovies) => {
        return RespAllMovies.cde_status != 'A'
      });
    },    
    rows() {
      return this.SearchList.length;
    },    
  }    
  }
</script>

<style scoped>
.admin-main .admin-form-login {
  padding: 40px 200px 80px 50px;    
}
</style>
